/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/11/21 16:00:04
 * @LastEditTime: 2023/12/05 09:44:13
 */
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuToRouter from '@/menuMapToRouter';
import MenuToBmsRouter from '@/menuMapToRouterBms';

class AuthorizedRoute extends React.Component {
  render() {
    const { component: Component, openAccessMenu, ...props } = this.props;
    const pathname = this.props.location.pathname;
    let name;
    if (
      localStorage.getItem('stationType') == 0 ||
      localStorage.getItem('stationType') == undefined
    ) {
      name = Object.keys(MenuToRouter).find(
        key => MenuToRouter[key] === pathname
      );
    } else {
      name = Object.keys(MenuToBmsRouter).find(
        key => MenuToBmsRouter[key] === pathname
      );
    }
    let hasPermission = true;
    if (name) {
      if (!openAccessMenu.some(s => s.name === name)) {
        hasPermission = false;
      }
    }
    return (
      <Route
        {...props}
        render={props => {
          return hasPermission ? <Component {...props} /> : null;
        }}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    openAccessMenu: state.app.openAccessMenu
  };
};
export default connect(mapStateToProps)(AuthorizedRoute);
