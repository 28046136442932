import { useTranslation } from 'react-i18next';
import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import MenuToRouter from '@/menuMapToRouter';
import MenuToBmsRouter from '@/menuMapToRouterBms';
import Icon, * as iconAll from '@ant-design/icons';
import { getOtaUrl } from 'api';
import { getToken } from '@/utils/token';
import { ICToBMSKeyMapping } from '@/i18n/ICToBMSKeyMapping';
// import { getCustomerComplaintToken } from '@/services/api';

const menuFunction = {
  firmwareUpdate: function () {
    getOtaUrl().then(rep => {
      if (rep && rep.code === 200) {
        window.open(rep.data, '_blank');
      }
    });
  },
  privacyPolicy: function () {
    window.open('https://www.alphaess.com/privacy-policy ', '_blank');
  },
  customerFeedback: function () {
    window.open(
      'https://support.alphaess.com/login' +
        '?token=' +
        getToken() +
        '&isAlphaCloud=1',
      '_blank'
    );
  },
  contactInfo: function () {
    window.open('https://www.alphaess.com/contact-us', '_blank');
  }
};

const { SubMenu } = Menu;
const { Item } = Menu;
const windowPageNameArray = [
  'firmwareUpdate',
  'privacyPolicy',
  'customerFeedback',
  'contactInfo'
];

//子菜单
const RenderMenuItem = (stationType, { name, title, icon, languageKey }) => {
  let link;
  if (
    localStorage.getItem('stationType') == 0 ||
    localStorage.getItem('stationType') == undefined
  ) {
    link = MenuToRouter[name];
  } else {
    link = MenuToBmsRouter[name];
  }

  const { t } = useTranslation(['leftMenu']);

  return (
    <Item key={name}>
      {windowPageNameArray.indexOf(name) > -1 ? (
        <span
          onClick={menuFunction[name]}
          className="event-dom"
          title={
            languageKey
              ? t(
                  stationType === 1 && ICToBMSKeyMapping[languageKey]
                    ? ICToBMSKeyMapping[languageKey]
                    : languageKey
                )
              : name
          }
        >
          {iconAll[icon] ? (
            icon && <Icon component={iconAll[icon]} />
          ) : (
            <i style={{ marginLeft: '20px' }}></i>
          )}
          <span
            title={
              languageKey
                ? t(
                    stationType === 1 && ICToBMSKeyMapping[languageKey]
                      ? ICToBMSKeyMapping[languageKey]
                      : languageKey
                  )
                : name
            }
          >
            {languageKey
              ? t(
                  stationType === 1 && ICToBMSKeyMapping[languageKey]
                    ? ICToBMSKeyMapping[languageKey]
                    : languageKey
                )
              : name}
          </span>
        </span>
      ) : link ? (
        <Link
          title={
            languageKey
              ? t(
                  stationType === 1 && ICToBMSKeyMapping[languageKey]
                    ? ICToBMSKeyMapping[languageKey]
                    : languageKey
                )
              : name
          }
          to={link}
        >
          <span>
            {iconAll[icon] ? (
              icon && <Icon component={iconAll[icon]} />
            ) : (
              <i style={{ marginLeft: '20px' }}></i>
            )}
            <span
              title={
                languageKey
                  ? t(
                      stationType === 1 && ICToBMSKeyMapping[languageKey]
                        ? ICToBMSKeyMapping[languageKey]
                        : languageKey
                    )
                  : name
              }
            >
              {languageKey
                ? t(
                    stationType === 1 && ICToBMSKeyMapping[languageKey]
                      ? ICToBMSKeyMapping[languageKey]
                      : languageKey
                  )
                : name}
            </span>
          </span>
        </Link>
      ) : (
        <span>
          {iconAll[icon] ? (
            icon && <Icon component={iconAll[icon]} />
          ) : (
            <i style={{ marginLeft: '20px' }}></i>
          )}
          <span
            title={
              languageKey
                ? t(
                    stationType === 1 && ICToBMSKeyMapping[languageKey]
                      ? ICToBMSKeyMapping[languageKey]
                      : languageKey
                  )
                : name
            }
          >
            {languageKey
              ? t(
                  stationType === 1 && ICToBMSKeyMapping[languageKey]
                    ? ICToBMSKeyMapping[languageKey]
                    : languageKey
                )
              : name}
          </span>
        </span>
      )}
    </Item>
  );
};

//子菜单的子菜单
const RenderSubMenu = (
  stationType,
  { name, title, icon, children, languageKey }
) => {
  const { t } = useTranslation(['leftMenu']);

  return (
    <SubMenu
      key={name}
      title={
        <span>
          {iconAll[icon] ? (
            icon && <Icon component={iconAll[icon]} />
          ) : (
            <i style={{ marginLeft: '20px' }}></i>
          )}
          <span
            title={
              languageKey
                ? t(
                    stationType === 1 && ICToBMSKeyMapping[languageKey]
                      ? ICToBMSKeyMapping[languageKey]
                      : languageKey
                  )
                : name
            }
          >
            {languageKey
              ? t(
                  stationType === 1 && ICToBMSKeyMapping[languageKey]
                    ? ICToBMSKeyMapping[languageKey]
                    : languageKey
                )
              : name}
          </span>{' '}
        </span>
      }
    >
      {children &&
        children.map(item =>
          item.children && item.children.filter(s => s.leftMenu).length > 0
            ? RenderSubMenu(stationType, item)
            : item.leftMenu
              ? RenderMenuItem(stationType, item)
              : null
        )}{' '}
    </SubMenu>
  );
};

//菜单的生成
export default ({ menus, ...props }) => (
  <Menu {...props}>
    {menus &&
      menus.map(item =>
        item.children && item.children.filter(s => s.leftMenu).length
          ? RenderSubMenu(props.stationType, item)
          : item.leftMenu
            ? RenderMenuItem(props.stationType, item)
            : null
      )}
  </Menu>
);
