import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthorizedRoute from '@/containers/AuthorizedRoute';
import Loadable from 'react-loadable';
import ContentLoader from '@/containers/AlphaESSContentLoader';
import { connect } from 'react-redux';

// 404页
const Page404 = Loadable({
  loader: () => import('@/pages/Page404'),
  loading: ContentLoader
});
// 欢迎页
const Welcome = Loadable({
  loader: () => import('@/pages/Welcome'),
  loading: ContentLoader
});
// 首页
const Home = Loadable({
  loader: () => import('@/pages/Home'),
  loading: ContentLoader
});
// 首页 BMS
const HomeBms = Loadable({
  loader: () => import('@/pages/HomeBms'),
  loading: ContentLoader
});
// 通道列表 Bms
const ChannelListBms = Loadable({
  loader: () => import('@/pages/BmsCloud/ChannelListBms'),
  loading: ContentLoader
});
// 系统主页
const SystemMainInterface = Loadable({
  loader: () => import('@/pages/mainSystemInterface/SystemMainInterface'),
  loading: ContentLoader
});
// 系统列表页
const SystemList = Loadable({
  loader: () => import('@/pages/SystemList'),
  loading: ContentLoader
});
// SN管理页
const SNManagement = Loadable({
  loader: () => import('@/pages/backstageManagement/SNManagement'),
  loading: ContentLoader
});
// 维修记录查询
const RepairRecord = Loadable({
  loader: () => import('@/pages/backstageManagement/RepairRecord'),
  loading: ContentLoader
});
// License管理页
const LicenseManagement = Loadable({
  loader: () => import('@/pages/backstageManagement/LicenseManagement'),
  loading: ContentLoader
});
// 电价设置页
const ElectricityPriceSetting = Loadable({
  loader: () => import('@/pages/basicManagementModule/ElectricityPriceSetting'),
  loading: ContentLoader
});
// 安装新系统
const InstallNewSystem = Loadable({
  loader: () =>
    import('@/pages/energyStorageSystemMaintenance/InstallNewSystem'),
  loading: ContentLoader
});
// 关联新系统
const AssociateNewDevice = Loadable({
  loader: () =>
    import('@/pages/energyStorageSystemMaintenance/AssociateNewDevice'),
  loading: ContentLoader
});
// 场站增加新通道
const DepotAddNewChannel = Loadable({
  loader: () =>
    import('@/pages/energyStorageSystemMaintenance/DepotAddNewChannel'),
  loading: ContentLoader
});
// 单体电池数据
const SingleBatteryData = Loadable({
  loader: () => import('@/pages/energyStorageData/SingleBattery'),
  loading: ContentLoader
});
// BMS的单体电池数据
const SingleBatteryDataBms = Loadable({
  loader: () => import('@/pages/BmsCloud/SingleBattery'),
  loading: ContentLoader
});
// 电池堆数据
const BatteryStackData = Loadable({
  loader: () => import('@/pages/BmsCloud/BatteryStackData'),
  loading: ContentLoader
});

// 修改密码
const ChangePassword = Loadable({
  loader: () => import('@/pages/ChangePassword'),
  loading: ContentLoader
});
// 运行日志
const RunLog = Loadable({
  loader: () => import('@/pages/RunLog'),
  loading: ContentLoader
});
// 报表管理
const ReportManagement = Loadable({
  loader: () => import('@/pages/ReportManagement'),
  loading: ContentLoader
});
// 告警信息页
const WarningMessage = Loadable({
  loader: () => import('@/pages/WarningMessage'),
  loading: ContentLoader
});
// 设备参数操作日志
const EquipmentParameterLog = Loadable({
  loader: () => import('@/pages/EquipmentParameterLog'),
  loading: ContentLoader
});
// 升级日志
const UpgradeLog = Loadable({
  loader: () => import('@/pages/UpgradeLog'),
  loading: ContentLoader
});

// 运行日志
const RunningLogInList = Loadable({
  loader: () => import('@/pages/RunningLog'),
  loading: ContentLoader
});

// 设备列表操作日志
const DeviceListLog = Loadable({
  loader: () => import('@/pages/DeviceListLog'),
  loading: ContentLoader
});
// 系统基本信息修改
const SysBasicInfoModification = Loadable({
  loader: () =>
    import('@/pages/energyStorageSystemMaintenance/BasicSysInfoSettings'),
  loading: ContentLoader
});

// 总览
const Pandect = Loadable({
  loader: () => import('@/pages/energyStorageData/Pandect'),
  loading: ContentLoader
});
// 自发自用
const PrivateUse = Loadable({
  loader: () => import('@/pages/controlStrategy/PrivateUse'),
  loading: ContentLoader
});
//远程通用设置
const UniversalRemoteSettings = Loadable({
  loader: () => import('@/pages/basicManagementModule/UniversalRemoteSettings'),
  loading: ContentLoader
});
//设备参数配置
const EquipmentParameter = Loadable({
  loader: () => import('@/pages/basicManagementModule/EquipmentParameter'),
  loading: ContentLoader
});
//设备管理
const DeviceManage = Loadable({
  loader: () => import('@/pages/basicManagementModule/DeviceManage'),
  loading: ContentLoader
});
//设备管理列表
const DeviceManageList = Loadable({
  loader: () => import('@/pages/basicManagementModule/DeviceManageList'),
  loading: ContentLoader
});
//系统高级参数
const SysAdvParameters = Loadable({
  loader: () => import('@/pages/basicManagementModule/SysAdvParameters'),
  loading: ContentLoader
});
//负载功率参数列表
const LoadPowerParameterList = Loadable({
  loader: () => import('@/pages/basicManagementModule/LoadPowerParameterList'),
  loading: ContentLoader
});
//维护参数配置
const MaintenanceParameter = Loadable({
  loader: () => import('@/pages/basicManagementModule/MaintenanceParameter'),
  loading: ContentLoader
});
// PCS数据
const PCSData = Loadable({
  loader: () => import('@/pages/controlStrategy/PCSData'),
  loading: ContentLoader
});
// 充电桩数据
const ChargingPileData = Loadable({
  loader: () => import('@/pages/chargingPile/ChargingPileData'),
  loading: ContentLoader
});
// 充电桩设置
const ChargingPileSettings = Loadable({
  loader: () => import('@/pages/chargingPile/ChargingPileSettings'),
  loading: ContentLoader
});
const PowerCurve = Loadable({
  loader: () => import('@/pages/powerCurve/powerCurve'),
  loading: ContentLoader
});
// 曲线分析
const PowerCurveBms = Loadable({
  loader: () => import('@/pages/BmsCloud/PowerCurveBms'),
  loading: ContentLoader
});
// 电量统计
const ElectricityStatistics = Loadable({
  loader: () => import('@/pages/electricityStatistics/electricityStatistics'),
  loading: ContentLoader
});
// 收益分析
const IncomeAnalysis = Loadable({
  loader: () => import('@/pages/incomeAnalysis/incomeAnalysis'),
  loading: ContentLoader
});
// 柴油机数据
const DieselEngineData = Loadable({
  loader: () => import('@/pages/energyStorageData/DieselEngineData'),
  loading: ContentLoader
});
// 电池簇数据
const ClusterData = Loadable({
  loader: () => import('@/pages/energyStorageData/ClusterData'),
  loading: ContentLoader
});
// 电池簇数据BMS
const ClusterDataBms = Loadable({
  loader: () => import('@/pages/BmsCloud/ClusterDataBms'),
  loading: ContentLoader
});

// 固件升级
const FirmwareUpdate = Loadable({
  loader: () => import('@/pages/systemManagement/FirmwareUpdate'),
  loading: ContentLoader
});
// 运行日志
const RunningLog = Loadable({
  loader: () => import('@/pages/backstageManagement/RunningLog'),
  loading: ContentLoader
});
// 用户日志
const UserLog = Loadable({
  loader: () => import('@/pages/backstageManagement/UserLog'),
  loading: ContentLoader
});
// 设备列表
const EquipmentList = Loadable({
  loader: () => import('@/pages/energyStorageData/EquipmentList'),
  loading: ContentLoader
});
// 用户信息修改
const UserInformationModification = Loadable({
  loader: () => import('@/pages/authManagement/UserInformationModification'),
  loading: ContentLoader
});
// 注销账号
const CancelAccount = Loadable({
  loader: () => import('@/pages/authManagement/cancelAccount'),
  loading: ContentLoader
});
// 产品资料
const ProductInformation = Loadable({
  loader: () => import('@/pages/dataCenter/productInformation'),
  loading: ContentLoader
});
// 产品资料列表
const ProductList = Loadable({
  loader: () => import('@/pages/dataCenter/productList'),
  loading: ContentLoader
});
// 资料中心管理
const DataCenterManage = Loadable({
  loader: () => import('@/pages/dataCenter/dataCenterManage'),
  loading: ContentLoader
});
// 资料栏目管理
const DataColumnManage = Loadable({
  loader: () => import('@/pages/dataCenter/dataColumnManage'),
  loading: ContentLoader
});
// 客户反馈
const CustomerFeedback = Loadable({
  loader: () => import('@/pages/CustomerFeedback'),
  loading: ContentLoader
});
// 联系信息
const ContactInfo = Loadable({
  loader: () => import('@/pages/ContactInfo'),
  loading: ContentLoader
});
// 隐私政策
const PrivacyPolicy = Loadable({
  loader: () => import('@/pages/PrivacyPolicy'),
  loading: ContentLoader
});
//example
const Page403 = Loadable({
  loader: () => import('@/pages/Page403'),
  loading: ContentLoader
});
// 通道列表
const ChannelList = Loadable({
  loader: () => import('@/pages/terminalList/ChannelList'),
  loading: ContentLoader
});
// 组态
//组态配置
const ToPoConfig = Loadable({
  loader: () => import('@/pages/webTopo/topoConfig'),
  loading: ContentLoader
});
//组态调整
const ToPoDebug = Loadable({
  loader: () => import('@/pages/webTopo/topoDebug'),
  loading: ContentLoader
});
//模板管理
const TemplateManage = Loadable({
  loader: () => import('@/pages/webTopo/templateManage'),
  loading: ContentLoader
});
//BMS-历史数据
const HistoryData = Loadable({
  loader: () => import('@/pages/bms/dataSearch/historyData'),
  loading: ContentLoader
});
//BMS-告警信息
const WarningInfo = Loadable({
  loader: () => import('@/pages/bms/dataSearch/warningInfo'),
  loading: ContentLoader
});
//BMS-实时数据
const RealData = Loadable({
  loader: () => import('@/pages/bms/dataSearch/realData'),
  loading: ContentLoader
});
//BMS-实时数据--堆数据详情
const RealDataStackDetail = Loadable({
  loader: () => import('@/pages/bms/dataSearch/realData/stackDetail'),
  loading: ContentLoader
});
//BMS-实时数据--簇数据列表
const ClusterDataList = Loadable({
  loader: () => import('@/pages/bms/dataSearch/realData/batteryClusterList'),
  loading: ContentLoader
});
//BMS-实时数据--热管设备列表
const HeatPipeEquipmentList = Loadable({
  loader: () => import('@/pages/bms/dataSearch/realData/heatPipeEquipmentList'),
  loading: ContentLoader
});
//BMS-实时数据--电池簇详细信息
const ClusterDetail = Loadable({
  loader: () => import('@/pages/bms/dataSearch/realData/clusterDetail'),
  loading: ContentLoader
});
//BMS-实时数据--电池包列表
const PackageList = Loadable({
  loader: () => import('@/pages/bms/dataSearch/realData/packageList'),
  loading: ContentLoader
});
//BMS-实时数据--电池包信息
const PackageDetail = Loadable({
  loader: () => import('@/pages/bms/dataSearch/realData/packageDetail'),
  loading: ContentLoader
});
//BMS-参数配置
const ParamsConfig = Loadable({
  loader: () => import('@/pages/bms/paramsConfig'),
  loading: ContentLoader
});
//BMS-系统操作
const SystemOperation = Loadable({
  loader: () => import('@/pages/bms/systemOperation'),
  loading: ContentLoader
});
// 设备运行数据
const DeviceOperatingData = Loadable({
  loader: () => import('@/pages/energyStorageData/DeviceOperatingData'),
  loading: ContentLoader
});
//升级管理->单次升级
const UpdateList = Loadable({
  loader: () => import('@/pages/updateManagement/updateList'),
  loading: ContentLoader
});
//升级管理->批量升级
const BatchUpgrade = Loadable({
  loader: () => import('@/pages/updateManagement/batchUpgrade'),
  loading: ContentLoader
});
const ModelList = Loadable({
  loader: () => import('@/pages/updateManagement/modelList'),
  loading: ContentLoader
});
//产品建议
const ProductSuggestions = Loadable({
  loader: () => import('@/pages/productSuggestions'),
  loading: ContentLoader
});
const stationAddNewSystem = Loadable({
  loader: () => import('@/pages/BmsCloud/StationAddNewSystem'),
  loading: ContentLoader
});
const AddNewStation = Loadable({
  loader: () => import('@/pages/BmsCloud/AddNewStation'),
  loading: ContentLoader
});
/* BMS报表管理 */
const ReportManagementBms = Loadable({
  loader: () => import('@/pages/BmsCloud/ReportManagementBms'),
  loading: ContentLoader
});
/* BMS上云新增页面 */
// bms场站列表
const StationList = Loadable({
  loader: () => import('@/pages/BmsCloud/StationList'),
  loading: ContentLoader
});

// bms电量统计
const BmsElectricityStatistics = Loadable({
  loader: () => import('@/pages/BmsCloud/ElectricityStatistics'),
  loading: ContentLoader
});

// 电池运行数据
const BmsDeviceOperatingData = Loadable({
  loader: () => import('@/pages/BmsCloud/DeviceOperatingData'),
  loading: ContentLoader
});

//远程通用设置x
const RemoteControl = Loadable({
  loader: () => import('@/pages/BmsCloud/RemoteControl'),
  loading: ContentLoader
});

/* 维护参数配置 */
const MaintainParameterConfig = Loadable({
  loader: () => import('@/pages/BmsCloud/MaintainParameterConfig'),
  loading: ContentLoader
});
// 辅控数据
const AuxiliaryControlData = Loadable({
  loader: () => import('@/pages/BmsCloud/AuxiliaryControlData'),
  loading: ContentLoader
});
// BMS 事件日志
const BMSEventLog = Loadable({
  loader: () => import('@/pages/BmsCloud/LogList/eventLog'),
  loading: ContentLoader
});
// BMS 运行日志
const BMSRunLog = Loadable({
  loader: () => import('@/pages/BmsCloud/LogList/runLog'),
  loading: ContentLoader
});
const mapStateToPorps = state => {
  let { dataType, stationType } = state.app;
  return { dataType, stationType };
};
//设备故障日志
// const EquipmentFailureLog = Loadable({
//   loader: () => import('@/pages/infoList/equipmentFailureLog/index'),
//   loading: ContentLoader
// });
//PCS远程参数设置
// const PCSRemoteParameterSetting = Loadable({
//   loader: () =>
//     import(
//       '@/pages/energyStorageSystemMaintenance/PCSRemoteParameterSetting/index'
//     ),
//   loading: ContentLoader
// });
//此处配置只对非Tab模式有效,Tab模式在menuMapToComponent.js配置
export default connect(mapStateToPorps)(props => {
  return (
    <Switch>
      <Route
        exact
        path={props.dataType == 'local' ? '/app/realData' : '/app/home'}
        component={
          props.dataType == 'local'
            ? RealData
            : props.stationType == 0
              ? Home
              : HomeBms
        }
      />
      <AuthorizedRoute exact path="/app/realData" component={RealData} />
      <AuthorizedRoute
        exact
        path="/app/realDataStackDataDetail"
        component={RealDataStackDetail}
      />
      <AuthorizedRoute
        exact
        path="/app/realDataClusterDataList"
        component={ClusterDataList}
      />
      <AuthorizedRoute
        exact
        path="/app/heatPipeEquipmentList"
        component={HeatPipeEquipmentList}
      />
      <AuthorizedRoute
        exact
        path="/app/realDataClusterDataDetail/:index"
        component={ClusterDetail}
      />
      <AuthorizedRoute
        exact
        path="/app/packageList/:id/:index"
        component={PackageList}
      />
      <AuthorizedRoute
        exact
        path="/app/packageDetail/:clusterIndex/:packageIndex/:packageId/:clusterId"
        component={PackageDetail}
      />
      <AuthorizedRoute exact path="/app/warningInfo" component={WarningInfo} />
      <AuthorizedRoute exact path="/app/historyData" component={HistoryData} />
      <Route
        exact
        path="/app/mainSystem/mainSystemInterface"
        component={SystemMainInterface}
      />
      <AuthorizedRoute
        exact
        path="/app/paramsConfig"
        component={ParamsConfig}
      />
      <AuthorizedRoute
        exact
        path="/app/systemOperation"
        component={SystemOperation}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/pandect"
        component={Pandect}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/universalRemoteSettings"
        component={UniversalRemoteSettings}
      />
      <AuthorizedRoute
        exact
        path="/app/systemManagement/remoteControl"
        component={RemoteControl}
      />
      {/* 维护参数配置 */}
      <AuthorizedRoute
        exact
        path="/app/systemManagement/maintainParameterConfig"
        component={MaintainParameterConfig}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/equipmentParameter"
        component={EquipmentParameter}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/deviceManage"
        component={DeviceManage}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/deviceManageList"
        component={DeviceManageList}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/SysAdvParameters"
        component={SysAdvParameters}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/loadPowerParameterList"
        component={LoadPowerParameterList}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/maintenanceParameter"
        component={MaintenanceParameter}
      />
      <AuthorizedRoute
        exact
        path="/app/controlStrategy/pscdata"
        component={PCSData}
      />
      <AuthorizedRoute
        exact
        path="/app/chargingPileData"
        component={ChargingPileData}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/chargingPileSettings"
        component={ChargingPileSettings}
      />
      <AuthorizedRoute
        exact
        path="/app/powerCurve/powerCurve"
        component={props.stationType == 0 ? PowerCurve : PowerCurveBms}
      />
      {/* 重复 */}
      {/* <AuthorizedRoute exact path="/app/runLog" component={RunLog} /> */}
      <AuthorizedRoute
        exact
        path="/app/warningMessage"
        component={WarningMessage}
      />
      {/* 报表管理 */}
      <AuthorizedRoute
        exact
        path="/app/reportManagement"
        component={
          props.stationType == 0 ? ReportManagement : ReportManagementBms
        }
      />
      <AuthorizedRoute exact path="/app/welcome" component={Welcome} />
      <AuthorizedRoute
        exact
        path="/app/equipmentParameterLog"
        component={EquipmentParameterLog}
      />
      <AuthorizedRoute exact path="/app/upgradeLog" component={UpgradeLog} />
      <AuthorizedRoute exact path="/app/warningInfo" component={WarningInfo} />
      <AuthorizedRoute exact path="/app/historyData" component={HistoryData} />
      <Route
        exact
        path="/app/mainSystem/mainSystemInterface"
        component={SystemMainInterface}
      />
      <Route
        exact
        path="/app/systemList"
        component={props.stationType == 0 ? SystemList : StationList}
      />
      <AuthorizedRoute
        exact
        path="/app/paramsConfig"
        component={ParamsConfig}
      />
      <AuthorizedRoute
        exact
        path="/app/systemOperation"
        component={SystemOperation}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/pandect"
        component={Pandect}
      />
      <AuthorizedRoute
        exact
        path="/app/controlStrategy"
        component={PrivateUse}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/universalRemoteSettings"
        component={UniversalRemoteSettings}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/equipmentParameter"
        component={EquipmentParameter}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/deviceManage"
        component={DeviceManage}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/deviceManageList"
        component={DeviceManageList}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/SysAdvParameters"
        component={SysAdvParameters}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/loadPowerParameterList"
        component={LoadPowerParameterList}
      />
      <AuthorizedRoute
        exact
        path="/app/basicManagementModule/maintenanceParameter"
        component={MaintenanceParameter}
      />
      <AuthorizedRoute
        exact
        path="/app/controlStrategy/pscdata"
        component={PCSData}
      />
      <AuthorizedRoute
        exact
        path="/app/chargingPileData"
        component={ChargingPileData}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/chargingPileSettings"
        component={ChargingPileSettings}
      />
      {/* 单体电池数据 */}
      <AuthorizedRoute
        exact
        path="/app/controlStrategy/singleBatteryData"
        component={
          props.stationType == 0 ? SingleBatteryData : SingleBatteryDataBms
        }
      />
      {/* 电池堆数据 */}
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/batteryStackData"
        component={BatteryStackData}
      />
      <AuthorizedRoute
        exact
        path="/app/powerCurve/powerCurve"
        component={PowerCurve}
      />
      <AuthorizedRoute exact path="/app/runLog" component={RunLog} />
      <AuthorizedRoute exact path="/app/welcome" component={Welcome} />
      <AuthorizedRoute
        exact
        path="/app/equipmentParameterLog"
        component={EquipmentParameterLog}
      />
      <AuthorizedRoute exact path="/app/upgradeLog" component={UpgradeLog} />

      <AuthorizedRoute
        exact
        path="/app/RunningLog"
        component={props.stationType == 0 ? RunningLogInList : BMSRunLog}
      />

      <AuthorizedRoute
        exact
        path="/app/deviceListLog"
        component={DeviceListLog}
      />
      <AuthorizedRoute
        exact
        path="/app/electricityStatistics/electricityStatistics"
        component={
          props.stationType == 0
            ? ElectricityStatistics
            : BmsElectricityStatistics
        }
      />
      <AuthorizedRoute
        exact
        path="/app/incomeAnalysis/incomeAnalysis"
        component={IncomeAnalysis}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/clusterData"
        component={props.stationType === 0 ? ClusterData : ClusterDataBms}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/dieselEngineData"
        component={DieselEngineData}
      />
      <AuthorizedRoute
        exact
        path="/app/systemManagement/firmwareUpdate"
        component={FirmwareUpdate}
      />
      <AuthorizedRoute
        exact
        path="/app/systemManagement/snManagement"
        component={SNManagement}
      />
      <AuthorizedRoute
        exact
        path="/app/systemManagement/repairRecord"
        component={RepairRecord}
      />
      <AuthorizedRoute
        exact
        path="/app/systemManagement/licenseManagement"
        component={LicenseManagement}
      />
      <AuthorizedRoute
        exact
        path="/app/backstageManagement/runningLog"
        component={RunningLog}
      />
      <AuthorizedRoute
        exact
        path="/app/backstageManagement/userLog"
        component={UserLog}
      />
      <AuthorizedRoute
        exact
        path="/app/authManagement/userInformationModification"
        component={UserInformationModification}
      />
      <AuthorizedRoute
        exact
        path="/app/authManagement/cancelAccount"
        component={CancelAccount}
      />
      <AuthorizedRoute
        exact
        path="/app/dataCenter/productInformation"
        component={ProductInformation}
      />
      <AuthorizedRoute
        exact
        path="/app/dataCenter/productList"
        component={ProductList}
      />
      <AuthorizedRoute
        exact
        path="/app/dataCenter/dataCenterManage"
        component={DataCenterManage}
      />
      <AuthorizedRoute
        exact
        path="/app/dataCenter/dataColumnManage"
        component={DataColumnManage}
      />
      <AuthorizedRoute
        exact
        path="/app/authManagement/changePassword"
        component={ChangePassword}
      />
      <AuthorizedRoute
        exact
        path="/app/authManagement/electricityPriceSetting"
        component={ElectricityPriceSetting}
      />
      <AuthorizedRoute
        exact
        path="/app/systemSettings/installNewSystem"
        component={InstallNewSystem}
      />
      <AuthorizedRoute
        exact
        path="/app/systemSettings/associateNewSystem"
        component={AssociateNewDevice}
      />
      <AuthorizedRoute
        exact
        path="/app/systemSettings/depotAddNewChannel"
        component={DepotAddNewChannel}
      />
      <AuthorizedRoute
        exact
        path="/app/systemSettings/basicSysInfoSettings"
        component={SysBasicInfoModification}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/equipmentList"
        component={EquipmentList}
      />
      <AuthorizedRoute exact path="/app/contactInfo" component={ContactInfo} />
      <AuthorizedRoute
        exact
        path="/app/privacyPolicy"
        component={PrivacyPolicy}
      />
      <AuthorizedRoute
        exact
        path="/app/customerFeedback"
        component={CustomerFeedback}
      />
      {/* 通道列表 */}
      <AuthorizedRoute
        exact
        path="/app/channelList"
        component={props.stationType == 0 ? ChannelList : ChannelListBms}
      />
      <AuthorizedRoute
        exact
        path="/app/webTopo/topoConfig"
        component={ToPoConfig}
      />
      <AuthorizedRoute
        exact
        path="/app/webTopo/topoDebug"
        component={ToPoDebug}
      />
      <AuthorizedRoute
        exact
        path="/app/webTopo/templateManage"
        component={TemplateManage}
      />
      <AuthorizedRoute
        exact
        path="/app/energyStorageData/DeviceOperatingData"
        component={
          props.stationType == 0 ? DeviceOperatingData : BmsDeviceOperatingData
        }
      />
      <AuthorizedRoute
        exact
        path="/app/updateManagement/updateList"
        component={UpdateList}
      />
      <AuthorizedRoute
        exact
        path="/app/updateManagement/batchUpgrade"
        component={BatchUpgrade}
      />
      <AuthorizedRoute
        exact
        path="/app/updateManagement/modelList"
        component={ModelList}
      />
      <AuthorizedRoute
        exact
        path="/app/productSuggestions"
        component={ProductSuggestions}
      />
      <AuthorizedRoute
        exact
        path="/app/systemSettings/stationAddNewSystem"
        component={stationAddNewSystem}
      />
      <AuthorizedRoute
        exact
        path="/app/systemSettings/addNewStation"
        component={AddNewStation}
      />
      <AuthorizedRoute
        exact
        path="/app/controlStrategy/auxiliaryControlData"
        component={AuxiliaryControlData}
      />
      <AuthorizedRoute exact path="/app/eventLog" component={BMSEventLog} />
      {/* <AuthorizedRoute
        exact
        path="/app/infoList/equipmentFailureLog"
        component={props.stationType === 0 ? EquipmentFailureLog : null}
      /> */}
      {/* <AuthorizedRoute
        exact
        path="/app/energyStorageSystemMaintenance/PCSRemoteParameterSetting"
        component={props.stationType === 0 ? PCSRemoteParameterSetting : null}
      /> */}
      <Route component={Page404} />
      <Route component={Page403} />
    </Switch>
  );
});
