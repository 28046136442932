import React from 'react';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import '@/style/login.less';
import { withTranslation } from 'react-i18next';
import { loginByUsername, getAccessMemu } from 'api';
import { setToken, getToken, removeToken } from '@/utils/token';
import LangSelect from '@/components/LangSelect';
import { injectUnMount } from '@/utils/handleUnMount';
import { getUserInfo } from 'api';
import { theme } from '@/theme';
import {
  updateLanguage,
  updateDataType,
  updateStationType
} from '@/actions/app';
import { connect } from 'react-redux';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './login.less';
import { encrypt } from '@/constants/common';
import { jwtDecode } from 'jwt-decode';
import { getDataCenterURL } from '../constants/dataCenter.js'
let number = 16666;

@withTranslation(['common'])
@injectUnMount
class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countNumber: 16666,
      showOrHideCookie: true
    };
    if (window.intervalTime) {
      clearTimeout(window.intervalTime);
      window.intervalTime = null;
    }
    let errorOperation = document.getElementById('error-operation');
    if (errorOperation) {
      errorOperation.parentNode.removeChild(errorOperation);
    }
  }
  startLogin = () => {
    this.setState({ loading: true });
  };
  endLogin = () => {
    this.setState({ loading: false });
  };
  handleSubmitSuccess = async values => {
    const { history, t } = this.props;
    this.startLogin();
    const userName = values.username.trim();
    const password = encrypt(values.password.trim(), values.username.trim());
    try {
      let res = await loginByUsername(userName, password);
      if (res && res.code === 200 && res.data) {
        const data = res.data;
        setToken(data.token || '');
        const jwtToken = jwtDecode(data.token); 
        localStorage.setItem('dataCenter',getDataCenterURL(jwtToken.sdc))
        getUserInfo()
          .then(async rep => {
            if (rep && rep.code === 200 && rep.data) {
              const tempMenu = await getAccessMemu({
                systemId: '4RCLmooUALfD',
                stationType: rep.data.stationType
              });
              if (
                !tempMenu ||
                !tempMenu.data ||
                !tempMenu.data.menu ||
                !tempMenu.data.menu.length
              ) {
                message.error(t('common.noMenuTips'), 5);
                removeToken();
                return;
              }
              localStorage.setItem('username', rep.data.username);
              localStorage.setItem(
                'BlmuInfo',
                JSON.stringify(rep.data.BlmuInfo)
              );
              localStorage.setItem(
                'BcmuCount',
                JSON.stringify(rep.data.BcmuCount)
              );
              localStorage.setItem(
                'BlmuCount',
                JSON.stringify(rep.data.BlmuCount)
              );
              let language = '';
              if (rep.data && rep.data.language) {
                language = rep.data.language.substr(0, 2).toLowerCase();
              }
              this.props.updateLanguage({ language: language });
              this.props.updateDataType('');
              if (rep.data.type == 'local') {
                history.push('/app/realData');
              } else {
                history.push('/');
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.endLogin();
    }
  };

  UNSAFE_componentWillMount() {
    const { history } = this.props;
    let token = getToken();
    if (token) {
      history.push('/');
    }
  }

  componentDidMount() {
    setTimeout(() => {
      let loading = document.getElementById('StartLoading');
      loading && document.body.removeChild(loading);
    }, 200);
    let model = 'light';
    window.less.modifyVars({
      ...theme[model]
    });
    this.setImgSrc();
    this.timerID = setInterval(() => {
      number = number + 1;
      this.tick(number);
    }, 1000);
  }

  hideCookieTips = () => {
    this.setState({
      showOrHideCookie: false
    });
  };

  setImgSrc() {
    let imgUrl = '/static/images/login_bg.jpg';
    let imgObject = new Image();
    imgObject.onload = function () {
      if (document.querySelector('.login-container')) {
        document.querySelector('.login-container').style.background =
          `url(${imgUrl}) no-repeat scroll center center`;
        document.querySelector('.login-container').style.backgroundSize =
          'cover';
      }
    };
    imgObject.src = imgUrl;
  }

  componentWillUnmount = () => {
    clearInterval(this.timerID);
    this.setState = (state, callback) => {
      return;
    };
  };

  tick(number) {
    let num = (number + '').replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
    this.setState({
      countNumber: num
    });
  }

  render() {
    const { t } = this.props;
    const form = (
      <Form
        size="large"
        layout="vertical"
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={this.handleSubmitSuccess}
      >
        <Form.Item
          label={t('common.account')}
          name="username"
          rules={[{ required: true, message: t('common.inputusername') }]}
        >
          <Input
            maxLength={64}
            className="antd-input-no-bg"
            autoComplete="false"
            placeholder={t('common.userName')}
          />
        </Form.Item>
        <Form.Item
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.inputpassword') }]}
        >
          <Input.Password
            maxLength={20}
            className="antd-input-no-bg"
            autoComplete="false"
            placeholder={t('common.password')}
          />
        </Form.Item>
        <Form.Item>
          <a className="login-form-forgot" href="/#/forgetPassword">
            {t('common.forgetpassword')}
          </a>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            className="btn-radius_25 btn_white"
            loading={this.state.loading}
          >
            {t('common.login')}
          </Button>
        </Form.Item>
        <Form.Item>
          <a className="login-form-register" href="/#/register">
            {t('common.register')}
          </a>
        </Form.Item>
      </Form>
    );

    return (
      <div className="login-container">
        {/*公司logo */}
        <div className="logo">
          <span className="logo-content">
            <img alt="logo" src="/static/images/alpha-logo.png" />
          </span>
          <ul className="more-info">
            <li key="AlphaESSOfficialWebsite">
              <a href="https://www.alphaess.com/" target="_blank">
                {t('common.AlphaESSOfficialWebsite')}
              </a>
            </li>
            <li className="separate">|</li>
            <li key="contactUs">
              <a href="https://www.alphaess.com/contact-us" target="_blank">
                {t('common.contactUs')}
              </a>
            </li>
          </ul>
          <div className="switch-language">
            <LangSelect />
          </div>
        </div>
        <Row justify="middle" align="right" className="layout-right">
          <Col>
            <Card
              hoverable
              bordered={false}
              cover={
                <div className="login-bg">
                  <ul>
                    <li>
                      <strong>{t('common.loginTitle')}</strong>
                    </li>
                  </ul>
                  {form}
                </div>
              }
            ></Card>
          </Col>
        </Row>
        <div className="layout-left">
          <div className="layout-box">
            {/* <p className="p1">TOWARDS</p> */}
            <p className="p2">{t('common.carbonNeutral')}</p>
            <p className="p3">
              <strong>6630</strong>&nbsp;kg
            </p>
            {/* <p className="p3">{this.state.countNumber}</p> */}
            <div className="mb20 overflow_hidden">
              <img src="/static/svg/1.svg" alt="" className="login-icon" />
              <span className="login-bar">
                <span className="login-bar-animation yellow"></span>
              </span>
            </div>
            <div className="mb20 overflow_hidden">
              <img src="/static/svg/2.svg" alt="" className="login-icon" />
              <span className="login-bar">
                <span className="login-bar-animation blue"></span>
              </span>
            </div>
            <div className="mb20 overflow_hidden">
              <img src="/static/svg/3.svg" alt="" className="login-icon" />
              <span className="login-bar">
                <span className="login-bar-animation white"></span>
              </span>
            </div>
          </div>
        </div>
        <div className="login-bottom">
          All rights reserved © Alpha ESS Co., Ltd.
        </div>
        <div
          className={
            this.state.showOrHideCookie ? 'cookie-tips' : 'cookie-tips-hide'
          }
        >
          <div>
            <div className="exclamationCircle">
              <ExclamationCircleOutlined />
            </div>
            <span>{t('common.cookieTips')}</span>
            <a
              href="https://www.alphaess.com/enPrivacyPolicy.html"
              target="_blank"
              style={{ borderBottom: '2px solid' }}
            >
              {t('common.learnMore')}
            </a>
          </div>
          <div
            className="cookie-tips-close"
            onClick={() => {
              this.hideCookieTips();
            }}
          >
            <CloseOutlined />
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateLanguage: lang => {
      dispatch(updateLanguage(lang));
    },
    updateDataType: dataType => {
      dispatch(updateDataType(dataType));
    },
    updateStationType: stationType => {
      dispatch(updateStationType(stationType));
    }
  };
};
export default connect(null, mapDispatchToProps)(Login);
