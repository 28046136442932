/*
 * @Description:边界
 * @Author: Summer.Chen
 * @Date: 2022-10-17 14:21:10
 * @LastEditTime: 2024-03-20 11:42:17
 * @LastEditors: Summer.Chen
 */
import React from 'react';
import { connect } from 'react-redux';
import { updateModule } from '@/actions/app';
import AlphaESSSider from '@/components/AlphaESSSider';
import MenuToRouter from '@/menuMapToRouter';
import MenuToBmsRouter from '@/menuMapToRouterBms';
import util from '@/utils/util';

class AlphaESSSiderContainer extends React.PureComponent {
  state = {
    openKeys: [],
    selectedKey: ''
  };
  //初始化菜单
  initMenu = pathname => {
    let name;
    if (
      localStorage.getItem('stationType') == 0 ||
      localStorage.getItem('stationType') == undefined
    ) {
      name = Object.keys(MenuToRouter).find(
        key => MenuToRouter[key] === pathname
      );
    } else {
      name = Object.keys(MenuToBmsRouter).find(
        key => MenuToBmsRouter[key] === pathname
      );
    }

    if (name) {
      let parentKeys = util
        .getParentMenusByName(this.props.openAccessMenu, name)
        .map(item => {
          return item.name;
        });

      if (!this.props.collapsed) {
        //菜单收缩状态，回退或前进显示菜单 BUG
        this.setState({
          openKeys: parentKeys
        });
      }

      this.setState({
        selectedKey: name
      });
    }
  };
  setOpenKeys = collapsed => {
    if (!collapsed) {
      this.setState({
        openKeys: []
      });
    }
  };
  openMenu = v => {
    let parentKeys = util
      .getParentMenusByName(this.props.openAccessMenu, v[v.length - 1])
      .map(item => {
        return item.name;
      });
    this.setState({
      openKeys:
        this.state.openKeys.indexOf(v[v.length - 1]) === -1 ? parentKeys : []
    });
  };

  render() {
    return (
      <AlphaESSSider
        responsive={this.props.responsive}
        collapsed={this.props.collapsed}
        menus={this.props.menus}
        pathname={this.props.pathname}
        openMenu={this.openMenu}
        selectedKey={this.state.selectedKey}
        openKeys={this.state.openKeys}
        toggle={this.props.toggle}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    menus: state.app.moduleMenu,
    openAccessMenu: state.app.openAccessMenu,
    accessMenu: state.app.accessMenu
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateModule: module => {
      dispatch(updateModule(module));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(AlphaESSSiderContainer);
