import React from 'react';
import { connect } from 'react-redux';

class AlphaESSSpin extends React.PureComponent {
  render() {
    const PageRouters = this.props.pageRouters;
    return <PageRouters></PageRouters>;
  }
}

const mapStateToPorps = state => {
  const { spinLoading } = state.app;
  return { spinLoading };
};
export default connect(mapStateToPorps, null)(AlphaESSSpin);
